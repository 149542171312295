<template>
  <div class="edu-frame">
    <edu-nav-bar title="资料修改"></edu-nav-bar>
    <div class="content-frame">
      <div class="edu-group" style="margin: 20px auto;">
        <div class="step-tips">填写报名资料</div>
        <div class="step-tips-detail" style="color: black;">请填写您的基本资料（以下带<span style="color: red;">*</span>的是<span style="color: red;">必填项</span>）</div>

        <div style="background-color: rgb(252 252 252);padding: 8px 0px;margin-top: 8px;width: 98%;margin-left: auto;margin-right: auto;border: 1px solid lightgray;">
          <div class="step-tips-detail step-tips-detail-title" style="margin-top: 0px;">基本信息：</div>
          <div class="class-form">
            <van-field readonly v-model="formData.fullName" required label="姓名" placeholder="请输入姓名" clearable style="background-color: transparent;"/>
            <van-field required name="radio" label="性别"  style="background-color: transparent;">
              <template #input>
                <van-radio-group v-model="formData.sex" direction="horizontal">
                  <van-radio name="1">男</van-radio>
                  <van-radio name="2">女</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field required readonly clickable name="datetimePicker" :value="formData.birthday_GreStr" label="出生日期" placeholder="点击选择时间" @click="showBirthdayPicker = true"  style="background-color: transparent;"/>
            <van-field readonly v-model="formData.idCardNo" required label="身份证号码" placeholder="请输入身份证号码" clearable style="background-color: transparent;"/>
            <van-field required v-model="formData.nativePlace" label="籍贯" placeholder="请填写籍贯"  style="background-color: transparent;"/>
            <van-field required readonly clickable :value="formData.nationality" label="民族" placeholder="点击选择民族" @click="showNationalityPicker = true" style="background-color: transparent;"/>
            <van-field required readonly v-model="formData.politicsStatusStr" label="政治面貌" placeholder="请选择政治面貌" clearable @click="showPolitics = true" style="background-color: transparent;"/>
            <van-field required v-model="formData.healthStatus" label="身体状况" placeholder="请输入疾病史" clearable style="background-color: transparent;"/>
            <van-field required v-model="formData.professionalTitle" label="专业资格职称" placeholder="请输入专业资格职称" clearable style="background-color: transparent;"/>

          </div>
        </div>


        <div style="background-color: rgb(252 252 252);padding: 8px 0px;margin-top: 8px;width: 98%;margin-left: auto;margin-right: auto;border: 1px solid lightgray;">
          <div class="step-tips-detail step-tips-detail-title" style="margin-top: 0px;">联系方式：</div>
          <div class="class-form">
            <van-field required v-model="formData.mobileTelNo" label="手机号码" placeholder="请输入手机号码" clearable  style="background-color: transparent;"/>
            <van-field required v-model="formData.wechat" label="微信号" placeholder="请输入微信号" clearable  style="background-color: transparent;"/>
            <van-field required v-model="formData.urgencyMan" label="紧急联系人" placeholder="请输入紧急联系人" clearable style="background-color: transparent;"/>
            <van-field required v-model="formData.urgencyTelNo" label="联系人电话" placeholder="请输入联系人电话" clearable  style="background-color: transparent;"/>
            <van-field required v-model="formData.homeAddress" label="现住址" placeholder="请输入现住址" clearable  style="background-color: transparent;"/>
            <div style="font-size: 14px;color: #9a9a9a;display: flex;justify-content: flex-end;margin-top: 5px;"><span style="color: red;">*</span>填写范例：xx省xx市xx区+填写详细门牌等讯息</div>
          </div>
        </div>


        <!-- 学历(专业)教育经历 -->
        <div style="background-color: rgb(252 252 252);padding: 8px 0px;margin-top: 8px;width: 98%;margin-left: auto;margin-right: auto;border: 1px solid lightgray;">
          <div class="step-tips-detail step-tips-detail-title" style="margin-top: 0px;">
            <div style="flex: 1;">学历(专业)教育经历：</div>
            <div style="display: flex;">
              <div class="add-detail-btn" @click="addEduExperience">
                <van-icon name="plus" size="15" color="white"/>
              </div>
            </div>
          </div>

          <div class="class-form" style="color: #646566;font-size: 14px;">
            <div class="class-form-line">
              <div style="flex: 1.4;text-align: center;">起止时间</div>
              <div style="flex: 0.9;text-align: center;">学校或机构名称</div>
              <div style="flex: 0.5;text-align: center;">系别</div>
              <div style="width: 14px;"></div>
            </div>
            <div class="class-form-line" v-for="(e, index) in eduExperiences" :key="index" @click="editEduExperience(index)">
              <div style="flex: 1.4;text-align: center;display: flex;justify-content: center;align-items: center;height: 100%;">
                <div style="border-bottom: 1px solid lightgray;margin-right: 5px;width: 100%;height: 100%;">{{ e.beginDate | filterYearMonth }}</div>
                -
                <div style="border-bottom: 1px solid lightgray;margin-left: 5px;width: 100%;height: 100%;">{{ e.endDate | filterYearMonth }}</div>
              </div>
              <div style="flex: 0.8;text-align: center;">
                <input v-model="e.schoolName" readonly type="text" style="background-color: transparent;border: 0;border-bottom: 1px solid lightgray;width: calc(100% - 20px);margin: 0 auto;">
              </div>
              <div style="flex: 0.5;text-align: center;">
                <input v-model="e.dep" readonly type="text" style="background-color: transparent;border: 0;border-bottom: 1px solid lightgray;width: calc(100% - 20px);margin: 0 auto;">
              </div>
              <div><van-icon name="cross" @click="deleteExperiences(index);"/></div>
            </div>
          </div>
          <div style="font-size: 14px;color: #9a9a9a;margin-left: 10px;">
            <span style="color: red;">*</span>
            填写说明（至少填写2项）：<br/>
            （1）本项需填写与任教专业相关的学历教育经历，主要为校内教育经历；<br/>
            （2）从最高学历开始填写（如博士-研究生-本科-职高中），职、高中以下学历不用填写。
          </div>
        </div>

        <!-- 学历(专业)教育经历 -->


        <!-- 专业相关成长经历 -->
        <div style="background-color: rgb(252 252 252);padding: 8px 0px;margin-top: 8px;width: 98%;margin-left: auto;margin-right: auto;border: 1px solid lightgray;">
          <div class="step-tips-detail step-tips-detail-title" style="margin-top: 0px;">
            <div style="flex: 1;">专业相关成长经历：</div>
            <div style="display: flex;">
              <div class="add-detail-btn" @click="addGrowUpExperience">
                <van-icon name="plus" size="15" color="white"/>
              </div>
            </div>
          </div>
          <div class="class-form" style="color: #646566;font-size: 14px;">
            <div class="class-form-line">
              <div style="flex: 0.5;text-align: center;">时间</div>
              <div style="flex: 1;text-align: center;">单位或机构名称</div>
              <div style="width: 14px;"></div>
            </div>
            <div class="class-form-line" v-for="(gu, index) in growUpExperiences" :key="index" @click="editGrowUpExperience(index)">
              <div style="flex: 0.5;text-align: center;">
                <div style="border-bottom: 1px solid lightgray;margin-right: 5px;">{{ gu.beginDate | filterYearMonth }}</div>
              </div>
              <div style="flex: 1;text-align: center;">
                <input v-model="gu.schoolName" readonly type="text" style="background-color: transparent;border: 0;border-bottom: 1px solid lightgray;width: calc(100% - 20px);margin: 0 auto;">
              </div>
              <div><van-icon name="cross" @click="deleteGrowpup(index)"/></div>
            </div>
          </div>
          <div style="font-size: 14px;color: #9a9a9a;margin-left: 10px;">
            <span style="color: red;">*</span>
            填写说明（至少填写1项）：<br/>
            本项需填写与任教专业相关的提升进修经历，主要为单位或机构培训成长经历。
          </div>
        </div>
        <!-- 专业相关成长经历 -->


        <!-- 所获荣誉(奖项)经历 -->
        <div style="background-color: rgb(252 252 252);padding: 8px 0px;margin-top: 8px;width: 98%;margin-left: auto;margin-right: auto;border: 1px solid lightgray;">
          <div class="step-tips-detail step-tips-detail-title" style="margin-top: 0px;">
          <div style="flex: 1;">所获荣誉(奖项)经历：</div>
          <div style="display: flex;">
            <div class="add-detail-btn" @click="addAwardExperience">
              <van-icon name="plus" size="15" color="white"/>
            </div>
          </div>
        </div>
          <div class="class-form" style="color: #646566;font-size: 14px;">
            <div class="class-form-line">
              <div style="flex: 1;text-align: center;">起止时间</div>
              <div style="flex: 1;text-align: center;">学校或机构名称</div>
              <div style="flex: 0.5;text-align: center;">系别</div>
              <div style="width: 14px;"></div>
            </div>
            <div class="class-form-line" v-for="(sc,index) in awards" :key="index" @click="editAward(index)">
              <div style="flex: 1;text-align: center;">
                <div style="border-bottom: 1px solid lightgray;margin-right: 5px;">{{ sc.beginDate | filterYearMonth }}</div>
              </div>
              <div style="flex: 1;text-align: center;">
                <input v-model="sc.schoolName" readonly type="text" style="background-color: transparent;border: 0;border-bottom: 1px solid lightgray;width: calc(100% - 20px);margin: 0 auto;">
              </div>
              <div style="flex: 0.5;text-align: center;">
                <input v-model="sc.dep" readonly type="text" style="background-color: transparent;border: 0;border-bottom: 1px solid lightgray;width: calc(100% - 20px);margin: 0 auto;">
              </div>
              <div><van-icon name="cross" @click="deleteAward(index)"/></div>
            </div>
          </div>

          <div style="font-size: 14px;color: #9a9a9a;margin-left: 10px;">
            <span style="color: red;">*</span>
            填写说明（至少填写1项）：<br/>
            按所获荣誉（奖项）的时间由近及远、含金量由大到小填写。
          </div>
        </div>
        <!-- 所获荣誉(奖项)经历 -->


        <!-- 长任教的课程 -->
        <div style="background-color: rgb(252 252 252);padding: 8px 0px;margin-top: 8px;width: 98%;margin-left: auto;margin-right: auto;border: 1px solid lightgray;">
        <div class="step-tips-detail step-tips-detail-title" style="margin-top: 0px;">
          <div style="flex: 1;">擅长任教的课程：</div>
          <div style="display: flex;">
            <div class="add-detail-btn" @click="addSkillCourse">
              <van-icon name="plus" size="15" color="white"/>
            </div>
          </div>
        </div>
        <div class="class-form" style="color: #646566;font-size: 14px;">
          <div class="class-form-line">
            <div style="flex: 1;text-align: center;">课程名称</div>
            <div style="width: 14px;"></div>
          </div>
          <div class="class-form-line" v-for="(sc,index) in skillCourses" :key="index">
            <div style="flex: 1;text-align: center;">
              <input v-model="sc.schoolName" type="text" style="background-color: transparent;border: 0;border-bottom: 1px solid lightgray;width: calc(100% - 20px);margin: 0 auto;">
            </div>
            <div><van-icon name="cross" @click="deleteSkillCourse(index)"/></div>
          </div>
        </div>

          <div style="font-size: 14px;color: #9a9a9a;margin-left: 10px;">
            <span style="color: red;">*</span>
            填写说明（至少填写1项）：<br/>
            课程名称字数限制在8字以内。
          </div>
        </div>
        <!-- 长任教的课程 -->

        <!-- 曾(现)任教的机构 -->
        <div style="background-color: rgb(252 252 252);padding: 8px 0px;margin-top: 8px;width: 98%;margin-left: auto;margin-right: auto;border: 1px solid lightgray;">
          <div class="step-tips-detail step-tips-detail-title" style="margin-top: 0px;">
            <div style="flex: 1;">曾(现)任教的机构：</div>
            <div style="display: flex;">
              <div class="add-detail-btn" @click="addOrganization">
                <van-icon name="plus" size="15" color="white"/>
              </div>
            </div>
          </div>
          <div class="class-form" style="color: #646566;font-size: 14px;">
            <div class="class-form-line">
              <div style="flex: 1;text-align: center;">机构名称</div>
              <div style="width: 14px;"></div>
            </div>
            <div class="class-form-line" v-for="(org, index) in organizations" :key="index">
              <div style="flex: 1;text-align: center;">
                <input v-model="org.schoolName" type="text" style="background-color: transparent;border: 0;border-bottom: 1px solid lightgray;width: calc(100% - 20px);margin: 0 auto;">
              </div>
              <div><van-icon name="cross" @click="deleteOrganization(index)"/></div>
            </div>
          </div>
          <div style="font-size: 14px;color: #9a9a9a;margin-left: 10px;">
            <span style="color: red;">*</span>
            填写说明（至少填写2项）：<br/>
            按任教时间由近及远（现任-曾任）、机构相关度由高及低填写。
          </div>
        </div>
        <!-- 曾(现)任教的机构 -->


        <!-- 个人简介 -->
        <div style="background-color: rgb(252 252 252);padding: 8px 0px;margin-top: 8px;width: 98%;margin-left: auto;margin-right: auto;border: 1px solid lightgray;">
          <div class="step-tips-detail step-tips-detail-title" style="margin-top: 0px;">个人简介：</div>
          <div class="class-form" style="color: #646566;font-size: 14px;">
            <van-field v-model="formData.intro" rows="2" autosize maxlength="300" label="个人简介" placeholder="请输入个人简介" show-word-limit type="textarea" style="background-color: transparent;" />
          </div>
          <div style="font-size: 14px;color: #9a9a9a;margin-left: 10px;">
            <span style="color: red;">*</span>
            填写说明（本项必填）：<br/>
            请用一段话描述自己，如教学风格、特色、所教学生成就等，前面所填报的资料无需重复赘述，字数限制100-300字。
          </div>
        </div>
        <!-- 个人简介 -->

        <!-- 备注 -->
        <div style="background-color: rgb(252 252 252);padding: 8px 0px;margin-top: 8px;width: 98%;margin-left: auto;margin-right: auto;border: 1px solid lightgray;">
          <div class="step-tips-detail step-tips-detail-title">备注：</div>
          <div class="class-form" style="color: #646566;font-size: 14px;">
            <van-field v-model="formData.note" rows="2" autosize label="备注" placeholder="请输入备注" type="textarea" style="background-color: transparent;"/>
          </div>
          <div style="font-size: 14px;color: #9a9a9a;margin-left: 10px;">
            <span style="color: red;">*</span>
            填写说明（本项非必填）：<br/>
            上面填报内容未尽事宜，可简要补充说明。
          </div>
        </div>
        <!-- 备注 -->


        <!-- 近期免冠证件照 -->
        <div class="step-tips-detail step-tips-detail-title">照片：</div>

        <div class="class-form" style="color: #646566;font-size: 14px;">
          <div style="display: flex;margin-top: 8px;color: #39a9ed;">近期免冠证件照</div>
          <div style="display: flex;justify-content: center;">近期免冠证件照</div>

          <div class="photo-frame">
            <img v-if="formData.imgUrl == null || formData.imgUrl == ''" class="photo" src="../../statics/images/my.png">
            <img v-else class="photo" :src="formData.imgUrl">
            <div class="photo-tips">*请上传1张近期免冠证件照</div>
            <van-button type="default" style="margin-top: 10px" @click="chooseImage('imgUrl')">选择照片</van-button>
            <input style="display: none;" id="imgUrl" ref="imgUrl" type="file" accept="image/jpeg,image/jpg,image/png" @change="previewImgUrlFiles($event)">
          </div>
        </div>
        <!-- 近期免冠证件照 -->

        <hr style="border: 0;height: 1px;background-color: lightgray;width: 90%;margin: 0 auto 20px;"/>

        <!-- 个人形象照或生活照 -->
        <div class="class-form" style="color: #646566;font-size: 14px;margin-bottom: 10px;">
          <div style="display: flex;margin-top: 8px;color: #39a9ed;margin-left: 10px;margin-bottom: 3px;">个人形象照或生活照</div>
          <div style="font-size: 14px;color: #9a9a9a;margin-left: 10px;margin-bottom: 15px">
            <span style="color: red;">*</span>
            请提供1~2张能展现个人专业形象的、有亲和力的单人照，横构图照片为宜，勿上传背景杂乱或集体照。
          </div>
        </div>
        <div class="class-form" style="color: #646566;font-size: 14px;">
          <div style="display: flex;justify-content: center;">个人形象照或生活照1</div>
          <div class="photo-frame">
            <img v-if="formData.livingPhoto1 == null || formData.livingPhoto1 == ''" class="photo" src="../../statics/images/my.png">
            <img v-else class="photo" :src="formData.livingPhoto1 +  (formData.livingPhoto1.indexOf('base64') > -1 ? '' : '?date='+ (new Date().valueOf())) ">
            <div class="photo-tips">*请上传个人形象照或生活照1</div>
            <van-button type="default" style="margin-top: 10px" @click="chooseImage('livingPhoto1')">选择照片</van-button>
            <input style="display: none;" id="livingPhoto1" ref="livingPhoto1" type="file" accept="image/jpeg,image/jpg,image/png" @change="previewFiles($event)">
          </div>
        </div>

        <div class="class-form" style="color: #646566;font-size: 14px;">
          <div style="display: flex;justify-content: center;">个人形象照或生活照2</div>
          <div class="photo-frame">
            <img v-if="formData.livingPhoto2 == null || formData.livingPhoto2 == ''" class="photo" src="../../statics/images/my.png">
            <img v-else class="photo" :src="formData.livingPhoto2 + (formData.livingPhoto2.indexOf('base64') > -1 ? '' : '?date='+ (new Date().valueOf()))">
            <div class="photo-tips">*请上传个人形象照或生活照2（可选）</div>
            <van-button type="default" style="margin-top: 10px" @click="chooseImage('livingPhoto2')">选择照片</van-button>
            <input style="display: none;" id="livingPhoto2" ref="livingPhoto2" type="file" accept="image/jpeg,image/jpg,image/png" @change="previewFiles($event)">
          </div>
        </div>


        <!-- 个人形象照或生活照 -->


        <hr style="border: 0;height: 1px;background-color: lightgray;width: 90%;margin: 0 auto 20px;"/>

        <div class="class-form" style="color: #646566;font-size: 14px;margin-bottom: 10px;">
          <div style="display: flex;margin-top: 8px;color: #39a9ed;margin-left: 10px;margin-bottom: 3px;">课堂教学照片</div>
          <div style="font-size: 14px;color: #9a9a9a;margin-left: 10px;margin-bottom: 15px">
            <span style="color: red;">*</span>
            请提供1~3张能呈现个人教学状态、课堂气氛良好且展示个人正（侧）面的课堂教学照片，横构图为宜。
          </div>
        </div>

        <!-- 课堂教学照片1 -->
        <div class="class-form" style="color: #646566;font-size: 14px;">
          <div style="display: flex;justify-content: center;">课堂教学照片1</div>
          <div class="photo-frame">
            <img v-if="formData.classImgUrl1 == null || formData.classImgUrl1 == ''" class="photo" src="../../statics/images/my.png">
            <img v-else class="photo" :src="formData.classImgUrl1 + (formData.classImgUrl1.indexOf('base64') > -1 ? '' : '?date='+ (new Date().valueOf()))">
            <div class="photo-tips">*请上传课堂教学照片1</div>
            <van-button type="default" style="margin-top: 10px" @click="chooseImage('classImgUrl1')">选择照片</van-button>
            <input style="display: none;" id="classImgUrl1" ref="classImgUrl1" type="file" accept="image/jpeg,image/jpg,image/png" @change="previewFiles($event)">
          </div>
        </div>
        <!-- 课堂教学照片1 -->

        <!-- 课堂教学照片2 -->
        <div class="class-form" style="color: #646566;font-size: 14px;">
          <div style="display: flex;justify-content: center;">课堂教学照片2</div>
          <div class="photo-frame">
            <img v-if="formData.classImgUrl2 == null || formData.classImgUrl2 == ''" class="photo" src="../../statics/images/my.png">
            <img v-else class="photo" :src="formData.classImgUrl2 + (formData.classImgUrl2.indexOf('base64') > -1 ? '' : '?date='+ (new Date().valueOf()))">
            <div class="photo-tips">*请上传课堂教学照片2（可选）</div>
            <van-button type="default" style="margin-top: 10px" @click="chooseImage('classImgUrl2')">选择照片</van-button>
            <input style="display: none;" id="classImgUrl2" ref="classImgUrl2" type="file" accept="image/jpeg,image/jpg,image/png" @change="previewFiles($event)">
          </div>
        </div>
        <!-- 课堂教学照片2 -->

        <!-- 课堂教学照片3 -->
        <div class="class-form" style="color: #646566;font-size: 14px;">
          <div style="display: flex;justify-content: center;">课堂教学照片3</div>
          <div class="photo-frame">
            <img v-if="formData.classImgUrl3 == null || formData.classImgUrl3 == ''" class="photo" src="../../statics/images/my.png">
            <img v-else class="photo" :src="formData.classImgUrl3 + (formData.classImgUrl3.indexOf('base64') > -1 ? '' : '?date='+ (new Date().valueOf()))">
            <div class="photo-tips">*请上传课堂教学照片3（可选）</div>
            <van-button type="default" style="margin-top: 10px" @click="chooseImage('classImgUrl3')">选择照片</van-button>
            <input style="display: none;" id="classImgUrl3" ref="classImgUrl3" type="file" accept="image/jpeg,image/jpg,image/png" @change="previewFiles($event)">
          </div>
        </div>
        <!-- 课堂教学照片3 -->

      </div>
    </div>

    <!-- 保存按钮 -->
    <div class="bottom-frame">
      <van-button :loading="submitting" square block type="primary" @click="submit">保存</van-button>
    </div>
    <!-- 保存按钮 -->


    <!-- 选择生日 -->
    <van-popup v-model="showBirthdayPicker" position="bottom" :close-on-click-overlay="false">
      <van-datetime-picker v-model="formData.birthday_Gre" value type="date" title="选择年月日"  :min-date="minDate" :max-date="maxDate" @confirm="selectBirthday()" @cancel="showBirthdayPicker=false"/>
    </van-popup>
    <!-- 选择生日 -->

    <!--选择政治面貌-->
    <van-popup v-model="showPolitics" position="bottom" :close-on-click-overlay="false">
      <van-picker show-toolbar :columns="politicsOpts" @confirm="selectPolitics" @cancel="showPolitics=false;"/>
    </van-popup>
    <!--选择政治面貌-->

    <!--选择在职状况-->
    <van-popup v-model="showNationalityPicker" position="bottom" :close-on-click-overlay="false">
      <van-picker show-toolbar :columns="nationalityOpts" @confirm="selectNationality" @cancel="showNationalityPicker=false;"/>
    </van-popup>
    <!--选择在职状况-->


    <!-- 教育经历年月 -->
    <van-popup v-model="showEduExperiencesPicker" position="bottom" :close-on-click-overlay="false">
      <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate" :max-date="maxDate" :formatter="formatter" @confirm="confirmEduExperiences" @cancel="showEduExperiencesPicker=false"/>
    </van-popup>
    <!-- 教育经历年月 -->


    <!-- 专业相关成长经历 -->
    <van-popup v-model="showGrowUpExperiencesPicker" position="bottom" :close-on-click-overlay="false">
      <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate" :max-date="maxDate" :formatter="formatter" @confirm="confirmGrowUpExperiences" @cancel="showGrowUpExperiencesPicker=false"/>
    </van-popup>
    <!-- 专业相关成长经历 -->


    <!-- 所获荣誉经历 -->
    <van-popup v-model="showAwardsPicker" position="bottom" :close-on-click-overlay="false">
      <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate" :max-date="maxDate" :formatter="formatter" @confirm="confirmAwardsExperiences" @cancel="showAwardsPicker=false"/>
    </van-popup>
    <!-- 所获荣誉经历 -->

    <!-- 教育经历 -->
    <van-popup v-model="showEduExperiencesPopup" v-if="curEduExperiences != null" :close-on-click-overlay="false">
      <div style="width: 90vw;">
        <van-field label-width="100" readonly v-model="curEduExperiences.beginDate" label="开始时间" placeholder="请输入开始时间" @click="selectExperiencesDate('beginDate')"/>
        <van-field label-width="100" readonly v-model="curEduExperiences.endDate" label="结束时间" placeholder="请输入结束时间" @click="selectExperiencesDate('endDate')" />
        <van-field label-width="100" v-model="curEduExperiences.schoolName" label="学校或机构名称" placeholder="请输入学校或机构名称" />
        <van-field label-width="100" v-model="curEduExperiences.dep" label="系别" placeholder="请输入系别" />
      </div>
      <div style="display: flex;">
        <van-button type="danger" block @click="showEduExperiencesPopup = false">取消</van-button>
        <van-button type="primary" block @click="submitExperiences">确定</van-button>
      </div>
    </van-popup>
    <!-- 教育经历 -->

    <!-- 专业相关成长经历 -->
    <van-popup v-model="showGrowUpExperiencesPopup" v-if="curGrowUpExperiences != null" :close-on-click-overlay="false">
      <div style="width: 90vw;">
        <van-field label-width="100" readonly v-model="curGrowUpExperiences.beginDate" label="时间" placeholder="请输入时间" @click="selectGrowupDate('beginDate')"/>
        <van-field label-width="100" v-model="curGrowUpExperiences.schoolName" label="学校或机构名称" placeholder="请输入学校或机构名称" />
      </div>
      <div style="display: flex;">
        <van-button type="danger" block @click="showGrowUpExperiencesPopup = false">取消</van-button>
        <van-button type="primary" block @click="submitGrowUpExperiences">确定</van-button>
      </div>
    </van-popup>
    <!-- 专业相关成长经历 -->

    <!-- 所获荣誉 -->
    <van-popup v-model="showAwardsPopup" v-if="curAwards != null" :close-on-click-overlay="false">
      <div style="width: 90vw;">
        <van-field label-width="100" readonly v-model="curAwards.beginDate" label="时间" placeholder="请输入时间" @click="selectAwardsDate('beginDate')"/>
        <van-field label-width="100" v-model="curAwards.schoolName" label="学校或机构名称" placeholder="请输入学校或机构名称" />
        <van-field label-width="100" v-model="curAwards.dep" label="级别" placeholder="请输入级别" />
      </div>
      <div style="display: flex;">
        <van-button type="danger" block @click="showAwardsPopup = false">取消</van-button>
        <van-button type="primary" block @click="submitAwards">确定</van-button>
      </div>
    </van-popup>
    <!-- 所获荣誉 -->


  </div>
</template>
<script>
import EduNavBar from "@/components/EduNavBar";
import Dictionary from "@/api/Dictionary"
import {Button, DatetimePicker, Field, Picker, Popup, Radio, RadioGroup, Icon} from "vant";
import XEUtils from "xe-utils";
import Tools from "@/api/Tools";
import CustomerApi from "@/api/CustomerApi";
export default {
  components: {
    EduNavBar,
    VanButton: Button,
    VanField: Field,
    VanRadioGroup: RadioGroup,
    VanRadio: Radio,
    VanIcon: Icon,
    VanDatetimePicker: DatetimePicker,
    VanPopup: Popup,
    VanPicker: Picker
  },
  data() {
    return {
      cust: Tools.getCurCust(),
      formData: {},
      showBirthdayPicker: false,
      currentDate: new Date(),
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      showNationalityPicker: false,
      nationalityOpts: Dictionary.nationalityOpts,
      politicsOpts: [{ text: '中共党员', value: '2' },{ text: '中共预备党员', value: '4' }, { text: '共青团员', value: '1' }, // { text: '其他', value: '3' },
         { text: '民革党员', value: '5' }, { text: '民盟盟员', value: '6' }, { text: '民建会员', value: '7' },
        { text: '民进会员', value: '8' }, { text: '农工党党员', value: '9' }, { text: '致公党党员', value: '10' },
        { text: '九三学社社员', value: '11' },{ text: '台盟盟员', value: '12' }, { text: '无党派人士', value: '13' }, { text: '群众', value: '14' } ],
      showPolitics: false,
      showHealthStatus: false,
      submitting: false,

      eduExperiences: [],
      showEduExperiencesPicker: false,
      curEduExperiences: null,
      showEduExperiencesPopup: false,

      growUpExperiences: [],
      showGrowUpExperiencesPicker: false,
      curGrowUpExperiences: null,
      showGrowUpExperiencesPopup: false,


      awards: [],
      showAwardsPicker: false,
      curAwards: null,
      showAwardsPopup: false,


      skillCourses: [],
      organizations: [],

      experiencesDateField: null,

      imgField: ''
    }
  },
  filters: {
    filterYearMonth(e) {
      return e == null || e == '' ? '未选择' : XEUtils.toDateString(e, 'yyyy年MM月')
    }
  },
  methods: {
    formatter(type, val) {
      if (type === 'month') {
        return `${val}月`;
      }
      if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    selectBirthday() {
      // 选择出生日期
      this.$set(this.formData, 'birthday_GreStr', this.formData.birthday_Gre == null ? null : XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd'))
      this.showBirthdayPicker = false
    },
    selectPolitics(e) {
      // 选择政治面貌
      this.formData.politicsStatusStr = e.text
      this.formData.politicsStatus = e.value
      this.showPolitics = false
    },
    selectNationality(e) {
      // 选择在职状况
      this.formData.nationality = e.value
      this.showNationalityPicker = false
    },

    // 教育经历
    deleteExperiences(index) {
      event.cancelBubble = true;
      this.eduExperiences.splice(index, 1)
    },
    addEduExperience() {
      this.showEduExperiencesPopup = true
      this.curEduExperiences = { index: this.eduExperiences.length }
    },
    editEduExperience(index) {
      this.showEduExperiencesPopup = true
      this.curEduExperiences = this.eduExperiences[index]
      this.curEduExperiences.index = index
    },
    selectExperiencesDate(field) {
      this.experiencesDateField = field
      this.showEduExperiencesPicker = true
    },
    confirmEduExperiences(e) {
      this.curEduExperiences[this.experiencesDateField] = XEUtils.toDateString(e, 'yyyy-MM')
      this.showEduExperiencesPicker = false
    },
    submitExperiences() {
      var res = this.eduExperiences[this.curEduExperiences.index]
      if (res == undefined) {
        this.eduExperiences.push(this.curEduExperiences)
      } else {
        this.eduExperiences[this.curEduExperiences.index] = this.curEduExperiences
      }
      this.showEduExperiencesPopup = false
    },
    // 教育经历


    // 专业相关成长经历
    deleteGrowpup(index) {
      event.cancelBubble = true;
      this.growUpExperiences.splice(index, 1)
    },
    selectGrowupDate() {
      // this.curGrowUpExperiencesIndex = index
      this.showGrowUpExperiencesPicker = true
    },
    editGrowUpExperience(index) {
      this.showGrowUpExperiencesPopup = true
      this.curGrowUpExperiences = this.growUpExperiences[index]
      this.curGrowUpExperiences.index = index
    },
    addGrowUpExperience() {
      this.showGrowUpExperiencesPopup = true
      this.curGrowUpExperiences = { index: this.growUpExperiences.length }
    },
    confirmGrowUpExperiences(e) {
      this.curGrowUpExperiences.beginDate = XEUtils.toDateString(e, 'yyyy-MM')
      this.showGrowUpExperiencesPicker = false
    },
    submitGrowUpExperiences() {
      var res = this.growUpExperiences[this.curGrowUpExperiences.index]
      if (res == undefined) {
        this.growUpExperiences.push(this.curGrowUpExperiences)
      } else {
        this.growUpExperiences[this.curGrowUpExperiences.index] = this.curGrowUpExperiences
      }
      this.showGrowUpExperiencesPopup = false
    },
    // 专业相关成长经历

    // 所获荣誉
    addAwardExperience() {
      this.showAwardsPopup = true
      this.curAwards = { index: this.awards.length }
    },
    editAward(index) {
      this.showAwardsPopup = true
      this.curAwards = this.awards[index]
      this.curAwards.index = index
    },
    selectAwardsDate() {
      this.showAwardsPicker = true
    },
    deleteAward(index) {
      event.cancelBubble = true;
      this.awards.splice(index, 1)
    },
    confirmAwardsExperiences(e) {
      this.curAwards.beginDate = XEUtils.toDateString(e, 'yyyy-MM')
      this.showAwardsPicker = false
    },
    submitAwards() {
      var res = this.awards[this.curAwards.index]
      if (res == undefined) {
        this.awards.push(this.curAwards)
      } else {
        this.awards[this.curAwards.index] = this.curAwards
      }
      this.showAwardsPopup = false
    },
    // 所获荣誉

    addSkillCourse() {
      this.skillCourses.push({})
    },
    deleteSkillCourse(index) {
      event.cancelBubble = true;
      this.skillCourses.splice(index, 1)
    },
    addOrganization() {
      this.organizations.push({})
    },
    deleteOrganization(index) {
      event.cancelBubble = true;
      this.organizations.splice(index, 1)
    },
    previewImgUrlFiles() {
      var file = document.getElementById("imgUrl").files[0];
      // console.log(file)
      var self = this
      Tools.uploadFileByBase64(file, (e) => {
        console.log(e)
        self.$set(self.formData, 'imgUrl', e)
        self.$toast('请注意提交保存照片。')
      })
    },
    chooseImgUrlImage() {
      this.$refs.imgUrl.click()
    },
    previewFiles() {
      var file = document.getElementById(this.imgField).files[0];
      // console.log(file)
      var self = this
      Tools.uploadFileByBase64(file, (e) => {
        self.$set(self.formData, this.imgField, e)
        self.$toast('请注意提交保存照片。')
      })
    },
    chooseImage(imgField) {
      this.imgField = imgField
      this.$refs[imgField].click()
    },
    submit() {
      if (this.submitting) {
        return
      }
      // 提交修改的资料
      this.submitting = true
      this.$set(this.formData, 'birthday_Gre', XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd HH:mm:ss'))

      this.formData.eduExperiences = this.eduExperiences
      this.formData.growUpExperiences = this.growUpExperiences
      this.formData.awards = this.awards
      this.formData.skillCourses = this.skillCourses
      this.formData.organizations = this.organizations

      CustomerApi.completeJSZLMsg(this.formData).then(response => {
        this.submitting = false
        console.log(response)
        if (response.code==100) {
          this.$toast({message: response.msg});
          Tools.setCurCust(response.res)
          this.getJXZLDetails()
        }
      }).catch(() => { this.submitting = false })

    },
    getJXZLDetails() {
      var custId = this.formData.custId
      CustomerApi.getJXZLDetails({ custId: custId }).then(response => {
        console.log(response)
        this.awards = response.res.awards
        this.eduExperiences = response.res.eduExperiences
        this.growUpExperiences = response.res.growUpExperiences
        this.organizations = response.res.organizations
        this.skillCourses = response.res.skillCourses
      })
    }
  },
  mounted() {
    this.formData = Tools.getCurCust()
    this.getJXZLDetails()
    this.formData.birthday_Gre = this.formData.birthday_Gre == null ? new Date() : XEUtils.toStringDate(XEUtils.toDateString(this.formData.birthday_Gre), 'yyyy-MM-dd HH:mm:ss')
    this.$set(this.formData, 'birthday_GreStr', this.formData.birthday_Gre == null ? null : XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd'))
  }
}
</script>
<style scoped>
.photo-frame {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 15px auto;
}
.photo {
  width: 35%;
}
.photo-tips {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
.class-form-line {
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.step-tips-detail-title {
  color: black;font-weight: bold;
  display: flex;
}
.add-detail-btn {
  background-color: #1989fa;display: flex;justify-content: center;align-items: center;width: 23px;height: 23px;border-radius: 50%;
}
</style>
