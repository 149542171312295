var nationalityOpts = [
    { text: '汉族', value: '汉族' },
    { text: '蒙古族', value: '蒙古族'},
    { text: '回族', value: '回族'},
    { text: '藏族', value: '藏族'},
    { text: '维吾尔族', value: '维吾尔族' },
    { text: '苗族', value: '苗族'},
    { text: '彝族', value: '彝族'},
    { text: '壮族', value: '壮族'},
    { text: '布衣族', value: '布衣族'},
    { text: '朝鲜族', value: '朝鲜族'},
    { text: '满族', value: '满族'},
    { text: '侗族', value: '侗族'},
    { text: '瑶族', value: '瑶族'},
    { text: '白族', value: '白族'},
    { text: '土家族', value: '土家族'},
    { text: '哈尼族', value: '哈尼族'},
    { text: '哈萨克族', value: '哈萨克族'},
    { text: '傣族', value: '傣族'},
    { text: '黎族', value: '黎族'},
    { text: '傈傈族', value: '傈傈族'},
    { text: '佤族', value: '佤族'},
    { text: '畲族', value: '畲族'},
    { text: '高山族', value: '高山族'},
    { text: '拉枯族', value: '拉枯族'},
    { text: '水族', value: '水族'},
    { text: '东乡族', value: '东乡族'},
    { text: '纳西族', value: '纳西族'},
    { text: '景颇族', value: '景颇族'},
    { text: '柯尔克孜族', value: '柯尔克孜族'},
    { text: '土族', value: '土族'},
    { text: '达斡尔族', value: '达斡尔族'},
    { text: '仫佬族', value: '仫佬族'},
    { text: '羌族', value: '羌族'},
    { text: '布朗族', value: '布朗族'},
    { text: '撒拉族', value: '撒拉族'},
    { text: '毛南族', value: '毛南族'},
    { text: '仡佬族', value: '仡佬族'},
    { text: '锡伯族', value: '锡伯族'},
    { text: '阿昌族', value: '阿昌族'},
    { text: '普米族', value: '普米族'},
    { text: '塔吉克族', value: '塔吉克族'},
    { text: '怒族', value: '怒族'},
    { text: '乌兹别克族', value: '乌兹别克族'},
    { text: '俄罗斯族', value: '俄罗斯族'},
    { text: '鄂温克族', value: '鄂温克族'},
    { text: '德昂族', value: '德昂族'},
    { text: '保安族', value: '保安族'},
    { text: '裕固族', value: '裕固族'},
    { text: '京族', value: '京族'},
    { text: '塔塔尔族', value: '塔塔尔族'},
    { text: '独龙族', value: '独龙族'},
    { text: '鄂伦春族', value: '鄂伦春族'},
    { text: '赫哲族', value: '赫哲族'},
    { text: '门巴族', value: '门巴族'},
    { text: '珞巴族', value: '珞巴族'},
    { text: '基诺族', value: '基诺族'},
]

export default {
    nationalityOpts
}
